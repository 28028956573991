'use client'

import Image from 'next/image'
import {useEffect, useMemo, useRef, useState} from 'react'

import {analyticsService, personalizeService} from '@atorie/api/analytics'
import {StorefrontGenerated, StorefrontTypes} from '@atorie/api/types'
import {splitIntoGroups} from '@atorie/core/utils'
import {
  useAuthUser,
  useFeaturedImages,
  useShopifyProductsByIdsQuery,
  useShopifyProductsInfiniteQuery,
  useWindowSize,
} from '@atorie/hooks'

import VisionUploadPictureButton from '../button/vision-upload-picture'
import Hero from '../landing/hero'
import {ProductItem as ShopifyProductItem} from '../shopify/shopify-product-item'
import ProductModal from './shopify-product-modal'

const SCROLL_IMAGES_COUNT = 3

export default function ShopifyProducts() {
  const {data: products} = useShopifyProductsInfiniteQuery({
    sortKey: StorefrontTypes.ProductSortKeys.CreatedAt,
  })
  const {data: featuredImages} = useFeaturedImages({published: true})
  const [scrolled, setScrolled] = useState(false)
  const fileTriggerRef = useRef<HTMLInputElement>(null)

  const [groupSize, setGroupSize] = useState(2)

  const [selectedProduct, setSelectedProduct] =
    useState<StorefrontGenerated.ProductCardFragment | null>(null)

  const productsRows = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const productsFlatten = products?.pages.flatMap((page) => page.nodes) ?? []
    return splitIntoGroups(productsFlatten, groupSize)
  }, [products, groupSize])

  const featuredImagesRows = useMemo(() => {
    return splitIntoGroups(featuredImages ?? [], groupSize)
  }, [featuredImages, groupSize])

  const [width] = useWindowSize()
  const {user} = useAuthUser()

  // Load all products from featured images by ids
  const productIds =
    featuredImages?.map(
      (featuredImage) =>
        'gid://shopify/Product/' +
        featuredImage.shopify_product_match.shopify_product_id,
    ) ?? []
  const {data: featuredProducts} = useShopifyProductsByIdsQuery(productIds)

  // TODO: migrate to grid and use static
  useEffect(() => {
    if (width && width >= 1536) {
      // Tailwind's 2xl breakpoint is 1536px
      setGroupSize(4)
    } else if (width && width >= 1280) {
      // Tailwind's xl breakpoint is 1280px
      setGroupSize(4)
    } else if (width && width >= 640) {
      // Tailwind's sm breakpoint is 640px
      setGroupSize(3)
    } else {
      setGroupSize(2)
    }
  }, [width])

  const handleScroll = (e) => {
    const {scrollTop} = e.target
    setScrolled(scrollTop > 0)
  }

  return (
    <div
      className="max-w-screen size-full max-h-screen overflow-y-auto overflow-x-hidden overscroll-none"
      onScroll={handleScroll}
    >
      <ProductModal
        isOpen={selectedProduct !== null}
        product={selectedProduct}
        onClickOutside={() => setSelectedProduct(null)}
      />
      <div className="overscroll-none pt-1">
        <div key="hero">
          <Hero
            landingScrolled={scrolled}
            scrollImagesCount={SCROLL_IMAGES_COUNT}
            onUploadButtonClick={() => fileTriggerRef.current?.click()}
          />
        </div>
        <div className="relative flex h-16 w-full items-center justify-center">
          <div className="avenir text-center text-xl opacity-70">
            Trending matches
          </div>
        </div>

        {featuredImagesRows.map((row, rowIndex) => {
          if (!row) return null

          return (
            <div
              key={rowIndex}
              className="fadein-150 flex w-full justify-center gap-6 pb-6 sm:gap-8 sm:pb-8 lg:gap-10 lg:pb-10"
            >
              {row.map((featuredImage, i) => {
                const productId =
                  'gid://shopify/Product/' +
                  featuredImage.shopify_product_match.shopify_product_id
                const product = featuredProducts?.find(
                  (product) => product?.id === productId,
                )

                return (
                  <div
                    key={featuredImage.id}
                    className="relative mb-0 flex w-40 flex-col items-center lg:w-52 xl:w-60"
                  >
                    <Image
                      src={featuredImage.image_url}
                      alt={`Featured image ${i + 1}`}
                      className="aspect-[5/6] w-full cursor-pointer rounded-md object-cover shadow-[0px_0px_4px_2px_rgba(0,0,0,0.1)]"
                      width={300}
                      height={300}
                      onClick={() => {
                        setSelectedProduct(product ?? null)
                      }}
                    />
                    <div className="avenir mt-2 flex w-full justify-center gap-1 text-base text-zinc-800/90">
                      <span className="font-medium line-through opacity-50">
                        ${featuredImage.shopify_product_match.price}
                      </span>
                      <span className="font-black opacity-100 ">
                        $
                        {(
                          product?.variants.nodes[0].price.amount as string
                        )?.split('.')?.[0] ?? ''}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
        <div className="relative mt-2 flex h-16 w-full items-center justify-center bg-stone-100 lg:mt-0">
          <div className="avenir text-center text-xl opacity-70">
            Our favorite products
          </div>
        </div>
        {productsRows.map((row, rowIndex) => {
          if (!row) return null

          return (
            <div
              key={rowIndex}
              className="fadein-150 flex w-full justify-center gap-6 bg-stone-100 pb-6 sm:gap-8 sm:pb-8 lg:gap-10 lg:pb-10"
            >
              {row.map((product, i) => {
                const firstVariant =
                  product?.variants?.nodes?.[0] ||
                  (product?.variants as any)?.edges?.[0].node
                return (
                  <button
                    key={product.id}
                    onClick={() => {
                      analyticsService.recordEvent({
                        name: 'PRODUCT_CLICKED',
                        attributes: {
                          userId: user?.id ?? '',
                          productId: product.id,
                          origin: 'the-same-same',
                        },
                      })
                      personalizeService.recordEvent({
                        eventType: 'PRODUCT_CLICKED',
                        userId: user?.id ?? '',
                        properties: {
                          itemId: firstVariant.id,
                        },
                      })
                      setSelectedProduct(
                        product as StorefrontGenerated.ProductCardFragment,
                      )
                    }}
                    className="relative w-40 lg:w-52 xl:w-60"
                  >
                    <ShopifyProductItem
                      product={product}
                      className="w-full shadow-[0px_0px_4px_2px_rgba(0,0,0,0.1)]"
                    />
                  </button>
                )
              })}
            </div>
          )
        })}
      </div>
      <VisionUploadPictureButton
        ref={fileTriggerRef}
        isMinimized={scrolled}
        className={`avenir fadein-300 absolute z-20 text-lg transition-all ${scrolled ? 'top-[80%] size-14 rounded-xl drop-shadow-md' : 'pointer-events-none top-[178px] h-12 w-52 rounded-[30px] lg:top-[222px]'}`}
      >
        Upload image
      </VisionUploadPictureButton>
    </div>
  )
}
