import algoliasearch from 'algoliasearch'
import algoliarecommend from '@algolia/recommend'

import {AlgoliaPaginated, Product} from './products'

type AlgoliaSearchClient = ReturnType<typeof algoliasearch>
type AlgoliaSearchIndex = ReturnType<AlgoliaSearchClient['initIndex']>
type AlgoliaRecommendClient = ReturnType<typeof algoliarecommend>

const indexName = 'products'

export class AlgoliaService {
  private static instance: AlgoliaService
  private recommendClient: AlgoliaRecommendClient
  private searchClient: AlgoliaSearchClient
  private searchIndex: AlgoliaSearchIndex

  public static getInstance(): AlgoliaService {
    if (!AlgoliaService.instance) {
      AlgoliaService.instance = new AlgoliaService()
    }

    return AlgoliaService.instance
  }

  private constructor() {
    this.recommendClient = algoliarecommend(
      process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
      process.env.NEXT_PUBLIC_ALGOLIA_API_KEY as string,
    )
    this.searchClient = algoliasearch(
      process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
      process.env.NEXT_PUBLIC_ALGOLIA_API_KEY as string,
    )
    this.searchIndex = this.searchClient.initIndex(indexName)
  }

  async getRelatedProductsRecommendations(product_id: string) {
    const {results} = await this.recommendClient.getRelatedProducts([
      {
        indexName,
        objectID: product_id,
      },
    ])

    const products: Product[] = []
    for (const result of results) {
      for (const hit of result.hits) {
        const {objectID, _highlightResult, ...rest} = hit
        products.push({
          highlight_result: _highlightResult,
          page: result.page + 1,
          query_id: result.queryID as string,
          ...(rest as Product),
          id: objectID,
        })
      }
    }
    return {
      products: products,
      page: 1,
      total_pages: 1,
      total_hits: products.length,
      query_id: results[0]?.queryID ?? '',
    }
  }

  async getTrendingProductsRecommendations({
    page = 1,
    limit = 10,
  }: {
    page?: number
    limit?: number
  }) {
    const {results} = await this.recommendClient.getTrendingItems(
      [
        {
          indexName,
        },
      ],
      {
        page,
        hitsPerPage: limit,
      },
    )

    console.log(results)

    const products: Product[] = []
    for (const result of results) {
      for (const hit of result.hits) {
        const {objectID, _highlightResult, ...rest} = hit
        products.push({
          highlight_result: _highlightResult,
          page: result.page + 1,
          query_id: result.queryID as string,
          ...(rest as Product),
          id: objectID,
        })
      }
    }
    return {
      products: products,
      page: 1,
      total_pages: 1,
      total_hits: products.length,
      query_id: results[0]?.queryID ?? '',
    }
  }

  async searchProducts(
    query: string,
    page?: number,
    limit?: number,
  ): Promise<AlgoliaPaginated<Product>> {
    const hitsData = await this.searchIndex.search(query, {
      hitsPerPage: limit ?? 10,
      page: page ? page - 1 : 0,
      clickAnalytics: true,
    })

    const products = hitsData.hits.map((hit) => {
      const {objectID, _highlightResult, ...rest} = hit
      return {
        highlight_result: _highlightResult,
        page: hitsData.page + 1,
        query_id: hitsData.queryID as string,
        ...(rest as Product),
        id: objectID,
      }
    })

    return {
      products: products,
      page: hitsData.page + 1,
      total_pages: hitsData.nbPages,
      total_hits: hitsData.nbHits,
      query_id: hitsData.queryID as string,
    }
  }
}

export const algoliaService = AlgoliaService.getInstance()
