import clsx from 'clsx'
import Image from 'next/image'
import React, {useMemo} from 'react'

import {FeaturedImage} from '@atorie/api/admin'
import {StorefrontGenerated} from '@atorie/api/types'
import {useShopifyProductByIdQuery} from '@atorie/hooks'

import {ProductItem as ShopifyProductItem} from '../shopify/shopify-product-item'

interface ProductComparisonProps {
  selectedFeaturedImage: FeaturedImage | null
  currentShopifyProduct?: StorefrontGenerated.ProductCardFragment | null
  shopifyProductId?: string | null
  showInstruction?: boolean
  className?: string
}

export default function ProductComparison({
  selectedFeaturedImage,
  currentShopifyProduct = null,
  shopifyProductId = null,
  showInstruction = false,
  className,
}: ProductComparisonProps) {
  const {data: shopifyProduct} = useShopifyProductByIdQuery(
    `gid://shopify/Product/${shopifyProductId}`,
    {enabled: !!shopifyProductId && !currentShopifyProduct},
  )

  const product = useMemo(() => {
    if (currentShopifyProduct) return currentShopifyProduct
    if (shopifyProduct) return shopifyProduct
    return null
  }, [currentShopifyProduct, shopifyProduct])

  const calculateDiscount = () => {
    if (
      !selectedFeaturedImage?.shopify_product_match.price ||
      !product?.variants.nodes[0].price.amount
    ) {
      return 0
    }
    const designerPrice = selectedFeaturedImage.shopify_product_match.price
    const factoryPrice = parseFloat(
      product.variants.nodes[0].price.amount as string,
    )
    const discount = ((designerPrice - factoryPrice) / designerPrice) * 100
    return Math.round(discount)
  }

  const discountPercentage = calculateDiscount()

  return (
    <div
      className={clsx(
        'relative grid h-44 w-80 grid-cols-2 place-content-center place-items-center gap-10 text-sm sm:h-72 sm:w-[460px] sm:text-base 2xl:h-[328px] 2xl:w-[540px]',
        className,
      )}
    >
      <div className="flex aspect-square w-full flex-col items-center">
        {selectedFeaturedImage?.image_url ? (
          <Image
            src={selectedFeaturedImage.image_url}
            className="fadein-150 size-full rounded-lg object-cover opacity-60 transition-opacity"
            alt=""
            width={300}
            height={300}
            sizes="25vw"
          />
        ) : (
          <div className="size-full rounded-lg bg-black/20 transition-opacity" />
        )}
        <div className="mt-1 flex flex-col items-center justify-center font-thin text-zinc-800 opacity-50">
          Designer
        </div>

        <div className="text-md text-base font-bold line-through opacity-50">
          ${selectedFeaturedImage?.shopify_product_match.price}
        </div>
      </div>
      <h3 className="absolute mb-6 text-xl text-zinc-800/50">VS</h3>
      <div className="flex size-full flex-col items-center">
        <ShopifyProductItem
          product={product}
          className="aspect-square w-full"
        />
        <div className="relative mt-1 flex flex-col items-center justify-center text-zinc-800">
          Factory
        </div>
        <div className="relative text-base font-black">
          $
          {(product?.variants.nodes[0].price.amount as string)?.split(
            '.',
          )?.[0] ?? ''}
        </div>
        {showInstruction && (
          <div className="absolute -bottom-9 mt-1 w-36 rounded-full bg-white px-2 py-1.5 text-center text-sm shadow lg:-bottom-6 lg:w-auto lg:text-base">
            3. Shop & save {discountPercentage}%
          </div>
        )}
      </div>
    </div>
  )
}
