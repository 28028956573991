'use client'

import {useEffect} from 'react'

import ShopifyProducts from '@/components/shopify/shopify-product-list'
import {algoliaService} from '@atorie/api/algolia'
import {analyticsService} from '@atorie/api/analytics'
import {useAuthUser} from '@atorie/hooks'

export default function Home() {
  const {user} = useAuthUser()
  useEffect(() => {
    try {
      analyticsService.recordEvent({
        name: 'SAME_SAME_HOME_PAGE_VIEWED',
        attributes: {
          origin: 'the-same-same',
          userId: user?.id ?? '',
        },
      })
      getAlgolia()
    } catch (error) {
      console.error('Error recording event:', error)
    }
  }, [])

  const getAlgolia = async () => {
    const res = await algoliaService.searchProducts('pants', 1, 10)
    console.log('algolia res', res)
  }

  return <ShopifyProducts />
}
